<!-- 成人活动列表 -->
<template>
  <div class="box">
    <el-card class="box_card">
      <el-button
        v-if="role_type==1"
        type="primary"
        size="mini"
        icon="el-icon-plus"
        style="margin-bottom:20px"
        @click="add_or_edit(0)"
      >创建任务</el-button>
      <el-scrollbar style="height:100%">
        <div>
          <teacherTabel v-fence="{ data: '', functions: ['成人-老师活动列表'] }" :tableData="tableData"></teacherTabel>
          <studentTabel v-fence="{ data: '', functions: ['成人-学生活动列表'] }" :tableData="tableData"></studentTabel>
        </div>
      </el-scrollbar>
      <el-pagination
        background
        layout="prev, pager, next, sizes, jumper"
        :total="page.total"
        :current-page="page.pageNum"
        :page-size="page.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        @current-change="click_page"
        @size-change="click_size_page"
      ></el-pagination>
    </el-card>
    <addActivity :show_dialog="show_dialog" @go_list="go_list" :role_type="role_type" :data="data"></addActivity>
  </div>
</template>

<script>
import { studentGet, getThemeTreeByClaseId, getActivityPage } from '../../../api/api'
import moment from "moment";
import studentTabel from './components/studentTabel.vue'
import teacherTabel from './components/teacherTabel.vue'
import addActivity from './components/addActivity.vue'
export default {
  data () {
    return {
      tableData: [],
      page: {
        total: 1,
        pageNum: 1,
        pagesize: 10,
      },
      show_dialog: -1,
      role_type: -1,//1 老师  2 学生
      data: []
    }
  },
  mounted () {
    this.loadCase()
    this.analysis()
    this.loadList()
  },
  components: {
    addActivity,
    studentTabel,
    teacherTabel
  },

  methods: {
    // 加载专题
    loadCase () {
      getThemeTreeByClaseId({ claseId: this.$route.query.classId }).then(res => {
        if (res.code == 0) {
          // let arr = this.loadTree(res.data)
          this.data = res.data
          // this.data = arr
        }
      })
    },
    // 加载列表
    loadList () {
      if (this.role_type == 1) {
        let params = {
          "pageNum": this.page.pageNum,
          "pageSize": this.page.pagesize,
          "query": {
            "classId": this.$route.query.classId
          }
        }
        getActivityPage(params).then(res => {
          if (res.code == 0) {
            res.data.list.map(item => {
              item.avgAccuracy = item.avgAccuracy == null ? '0%' : item.avgAccuracy.toFixed(0) + '%'
              item.startTime = this.currentTime.formatDate(item.startTime * 1000).split(' ')[0]
              item.endTime = this.currentTime.formatDate(item.endTime * 1000).split(' ')[0]
            })
            this.tableData = res.data.list
            this.page.total = res.data.total
          } else {
            this.$message.error(res.message)
          }

        })
      } else if (this.role_type == 2) {
        let params = {
          "pageNum": 1,
          "pageSize": 10,
          "classId": Number(this.$route.query.classId)
        }
        studentGet(params).then(res => {
          if (res.code == 0) {
            res.data.list.map(item => {
              item.accuracy = (item.accuracy * 100).toFixed(0) + '%'
              item.fianlTime = item.fianlTime ? this.currentTime.formatDate(item.fianlTime * 1000) : '---'
              item.activityStart = this.currentTime.formatDate(item.activityStart * 1000).split(' ')[0];
              item.activityEnd = item.activityEnd ? this.currentTime.formatDate(item.activityEnd * 1000).split(' ')[0] : '---'
              item.zip = Number(((item.doCaseCount / item.caseCount) * 100).toFixed())
              let time = this.currentTime.currentTime()
              if (this.$route.query.dis_click == 1 || time > item.activityEnd) {
                item.dis_blom = true
              } else {
                item.dis_blom = false
              }
              if (item.zip == '100' && item.accuracy == '100%') {
                item.dis_practice = true
              } else {
                item.dis_practice = false
              }
            })
            this.tableData = res.data.list
          } else {
            this.$message.error(res.message)
          }
        })
      }

    },
    // 解析appIds
    analysis () {
      if (this.utils.identity("成人-老师活动列表")) {
        this.role_type = 1
      }
      if (this.utils.identity("成人-学生活动列表")) {
        this.role_type = 2
      }
    },
    // 弹框返回触发的方法
    go_list (val) {
      this.show_dialog = val
      this.loadList()
    },
    // 添加编辑弹框
    add_or_edit (type) {
      // type  0 新增  1 编辑
      if (type == 0) {
        if (this.$route.query.dis_click == 1) {
          this.$message.error('班级已结束，不能创建活动！')
        } else {
          this.show_dialog = type
        }
      } else if (type == 1) {
        this.show_dialog = type
      }
    },
    // 表格点击事件
    act_click (type, val) {
      // type  0 查看  1 进入  2 练习
      if (type == 0) {
        this.$router.push({ path: '/adultSummary' })
      } else if (type == 1) {
        this.$router.push({ path: '/adultActivityDetails' })
      } else if (type == 2) {
        this.$router.push({ path: '/adultEducation/answer' })
      }
    },
    // 分页操作
    click_page (val) {
      this.page.pageNum = val
      this.loadList()
    },
    click_size_page (val) {
      this.page.pagesize = val
      this.loadList()
    },
    //节点树拿出数据
    loadTree (val) {
      val.map(item => {
        item.last_child = []
        if (item.children.length > 0) {
          item.children.map(child_item => {
            val.push(child_item)
            if (child_item.children.length > 0) {
              this.loadTree(child_item.children)
            }
          })
        }
      })
      return val
    },
  }
}

</script>
<style lang='less' scoped>
@import './style/index.less';
</style>
