<!-- 新增/编辑 活动 -->
<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="900px"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- prop="name"  -->
        <el-form-item label="课程名称" class="form_item">
          <el-input
            v-model="ruleForm.name"
            size="mini"
            placeholder="请输入课程名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="任务类型" prop="activityType" class="form_item">
          <el-select
            v-model="ruleForm.activityType"
            placeholder="请选择任务类型"
            size="mini"
            style="width: 100%"
            :disabled="true"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始日期" prop="startDate" class="form_item">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="ruleForm.startDate"
            style="width: 100%"
            size="mini"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期" prop="endDate" class="form_item">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="ruleForm.endDate"
            style="width: 100%"
            size="mini"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="任务启动">
          <el-switch v-model="ruleForm.delivery"></el-switch>
        </el-form-item>
        <el-form-item label="活动描述" class="textarea">
          <el-input
            type="textarea"
            v-model="ruleForm.textDescribe"
            placeholder="1-200个字符"
            maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="自主选择" class="tree_div">
          <div class="tree_div_left">
            <!-- <el-button @click="handleCheckChange">通过 key 设置</el-button> -->
            <el-button
              type="primary"
              style="margin-left: 10px"
              @click="AllTree"
              size="mini"
              >全选</el-button
            >
            <el-button type="primary" @click="NAllTree" size="mini"
              >取消全选</el-button
            >
            <el-scrollbar style="height: 80%">
              <el-tree
                ref="tree"
                :data="data"
                :props="defaultProps"
                node-key="id"
                @node-click="click_tree"
                @check-change="handleCheckChange"
                show-checkbox
                :render-content="renderContent"
              ></el-tree>
            </el-scrollbar>
          </div>
          <div class="tree_div_right">
            <el-table
              height="260"
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 500px"
              :header-cell-style="{
                background: '#F3F7FF',
                color: '#0B1B31',
                fontSize: '14px',
                padding: '0',
              }"
            >
              <el-table-column prop="id" label="变式ID"></el-table-column>
              <el-table-column
                prop="caseSerial"
                label="变式编号"
              ></el-table-column>
              <el-table-column
                prop="caseName"
                label="变式名称"
              ></el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <el-form-item
          label="选择参与学生"
          v-if="$route.query.type == 1 || role_type == 1"
        >
          <el-transfer
            :titles="['未选中', '已选中']"
            v-model="ruleForm.transferValue"
            :data="student_data"
          ></el-transfer>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel_add_Activity" size="mini">取 消</el-button>
        <!-- <el-button size="mini">预览</el-button> -->
        <el-button type="primary" @click="add_Activity('ruleForm')" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getThemeCaseNamePage,
  GetStudentByGradeClass,
  getThemeTreeByClaseId,
  themeActivityAdd,
  getClassUsers,
} from "../../../../api/api";
export default {
  props: {
    show_dialog: {
      type: Number,
    },
    role_type: {
      type: Number,
    },
    data: {
      type: Array,
    },
  },
  data() {
    return {
      title: "创建任务",
      dialogVisible: false,
      ruleForm: {
        name: "",
        activityType: 1,
        startDate: "",
        endDate: "",
        delivery: true,
        textDescribe: "",
        num: 1,
        transferValue: [],
      },
      rules: {
        name: [{ required: true, message: "请输入课程名称", trigger: "blur" }],
        activityType: [
          { required: true, message: "请选择任务类型", trigger: "change" },
        ],
        startDate: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        endDate: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
      },
      typeOptions: [
        {
          id: 1,
          title: "专题",
        },
      ],
      // data: [],
      student_data: [],
      defaultProps: {
        children: "last_child",
        label: "title",
      },
      multipleSelection: [],
      tableData: [],
      tree_node: [],
    };
  },
  watch: {
    show_dialog(val, old) {
      if (val !== old) {
        this.dialogVisible = false;
        if (val == 0) {
          this.title = "创建任务";
          this.dialogVisible = true;
          this.loadStudent();
        } else if (val == 1) {
          this.title = "编辑任务";
        }
      }
    },
  },
  components: {},
  mounted() {},
  methods: {
    renderContent(h,{ node, data,store }){
      return(
        <span class="custom-tree-node">
          <span title={node.label}>{node.label.length > 10 ? node.label.slice(0,12)+'...' : node.label}</span>
        </span>
      )
    },
    // 获取学生
    loadStudent(val) {
      this.student_data = [];
      GetStudentByGradeClass({
        classId: this.$route.query.classId,
        isUser: 1,
      }).then((res) => {
        res.data.list.map((item) => {
          item["key"] = item.id;
          item["label"] = item.fullName;
        });
        this.student_data = res.data.list;
      });
      // getClassUsers({ classId: this.$route.query.classId, themeId: this.tree_node.id }).then((res) => {
      //   if (res.code == 0) {
      //     if (res.data.length > 0) {
      //       res.data.map((item) => {
      //         item["key"] = item.id;
      //         item["label"] = item.fullName;
      //       });
      //       this.student_data = res.data
      //     } else {
      //       this.$message.warning('该专题下的学生都已分配任务！')
      //     }
      //   } else {
      //     this.$message.error(res.message)
      //   }
      // });
    },
    // 点击树节点
    click_tree(node, data) {
      // this.tree_node = node;
      let params = {
        pageNum: null,
        pageSize: null,
        query: {
          themeId: node.id,
        },
      };
      getThemeCaseNamePage(params).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.list;
        } else {
          this.$message.error(res.message);
        }
      });
      // this.loadStudent();
    },
    // 确认表单
    add_Activity(formName) {
      // this.tree_node = this.$refs.tree.getCheckedKeys();
      this.$refs[formName].validate((valid) => {
        // &&this.tableData.length != 0 &&this.ruleForm.transferValue.length != 0
        if (valid) {
          let arr = [];
          this.tableData.forEach((item) => {
            arr.push(item.id);
          });
          if (this.role_type == 2) {
            var user_arr = [];
            user_arr.push(this.utils.userId());
          }

          var Promises = [];
          this.tree_node.map((i, index) => {
            var found = this.data.find(function (item) {
              return item.id == i;
            });

            let params = {
              activityName: this.ruleForm.name + found.title, //专题名称
              activityType: this.ruleForm.activityType, //活动类型 （现在只有1.专题）
              activitySet: null, //任务设置  1.时间 2.题量
              activityStart: this.currentTime.getTimestamp(
                this.ruleForm.startDate
              ), //开始时间（时间戳）
              activityEnd: this.ruleForm.endDate
                ? this.currentTime.getTimestamp(this.ruleForm.endDate)
                : "", //结束时间（时间戳）
              activityDescribe: this.ruleForm.textDescribe, //活动描述
              activityGuidance: "", //活动指导
              classId: Number(this.$route.query.classId), //班级名称
              // "userIds":[1094],//学生id数组
              userIds:
                this.role_type == 1
                  ? this.ruleForm.transferValue
                  : this.role_type == 2
                  ? user_arr
                  : null, //学生id数组
              // "caseIds": arr,//变式id数组
              caseIds: [], //变式id数组
              // themeId: this.tree_node.id, //专题id
              themeId: found.id, //专题id
              isStart: this.ruleForm.delivery ? 1 : 2,
            };
            var promis = themeActivityAdd(params);
            Promises.push(promis);
            // themeActivityAdd(params).then((res) => {
            //   if (res.code == 0) {
            //     // this.$message.success("创建成功！");
            //     // this.cancel_add_Activity();
            //     if (this.tree_node.length == index + 1) {
            //       this.$message.success("创建成功！");
            //       this.cancel_add_Activity();
            //     }
            //   } else {
            //     this.$message.error(res.message);
            //   }
            // });
          });
        } else {
          this.$message.error("请完善信息！");
          return false;
        }

        Promise.all(Promises).then(res=>{
           this.$message.success("创建成功！");
           this.cancel_add_Activity();
        }
        ).catch(err=>{
          this.$message.error("请求失败");
          this.cancel_add_Activity();
        }

        );
      });
    },
    handleCheckChange(data, checked, indeterminate) {
      this.tree_node = this.$refs.tree.getCheckedKeys();
    },
    AllTree() {
      this.$refs.tree.setCheckedNodes(this.data);
      this.tree_node = this.$refs.tree.getCheckedKeys();
    },
    NAllTree() {
      this.$refs.tree.setCheckedKeys([]);
      this.tree_node = this.$refs.tree.getCheckedKeys();
    },
    // 取消编辑
    cancel_add_Activity() {
      this.$emit("go_list", -1);
      this.$refs["ruleForm"].resetFields();
    },
    // 关闭二次确认
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.cancel_add_Activity();
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>
<style lang='less' scoped>
@import "../style/addActivity.less";
</style>
