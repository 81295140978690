<!-- 老师列表 -->
<template>
  <div>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      @row-click="act_click"
      :header-cell-style="headClass"
      :cell-style="headClass"
    >
      <el-table-column prop="activityId" label="专题ID" width="200"></el-table-column>
      <el-table-column prop="activityName" label="专题名称"></el-table-column>
      <el-table-column prop="startTime" label="开始时间" width="200"></el-table-column>
      <el-table-column prop="endTime" label="结束时间" width="200"></el-table-column>
      <el-table-column prop="caseTotal" label="总变式数" width="100"></el-table-column>
      <el-table-column prop="referCaseCount" label="涉及变式数" width="100"></el-table-column>
      <el-table-column prop="avgAccuracy" label="平均正确率" width="100"></el-table-column>
      <el-table-column prop="avgAccuracy" label="平均内化率" width="160"></el-table-column>
      <el-table-column prop="reachedStandardUserCount" label="达标人数" width="160"></el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.enabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="change_switch(scope.row)"
            @click.stop.native
          ></el-switch>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { toggleActivityEnabled } from '../../../../api/api'
export default {
  props: {
    tableData: {
      type: []
    }
  },
  data () {
    return {
      // tableData: [],
    }
  },

  components: {},
  mounted () {
  },
  methods: {
    headClass () { //表头居中显示
      return "text-align:center"
    },
    // 点击开关
    change_switch (val) {
      toggleActivityEnabled({ activityId: val.activityId, enabled: val.enabled }).then(res => {
        if (res.code !== 0) {
          this.$message.error(res.message)
        }
      })
    },
    // 表格点击事件
    act_click (row) {
      // type  0 查看  1 进入  2 练习
      this.$router.push({ path: '/adultEducation/teacher/ActivityDetails', query: { activityId: row.activityId } })
    },
  }
}

</script>
<style lang='less' scoped>
</style>
