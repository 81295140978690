<!-- 学生表格 -->
<template>
  <div>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      :header-cell-style="headClass"
      :cell-style="headClass"
    >
      <el-table-column prop="activityId" label="活动ID" width="80"></el-table-column>
      <el-table-column prop="activityName" label="活动名称"></el-table-column>
      <el-table-column label="任务进度" width="200">
        <template slot-scope="scope">
          <el-progress :percentage="scope.row.zip"></el-progress>
        </template>
      </el-table-column>
      <el-table-column prop="activityStart" label="开始时间" width="120"></el-table-column>
      <el-table-column prop="activityEnd" label="结束时间" width="120"></el-table-column>
      <el-table-column prop="caseCount" label="总题量" width="100"></el-table-column>
      <el-table-column prop="doCaseCount" label="完成量" width="100"></el-table-column>
      <el-table-column prop="accuracy" label="正确率" width="100"></el-table-column>
      <el-table-column prop="isReach" label="是否达标" width="100">
        <template slot-scope="scope">{{scope.row.isReach==1?'达标':'未达标'}}</template>
      </el-table-column>
      <el-table-column prop="fianlTime" label="最后一次完成时间" width="160"></el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="act_click(0,scope.row)"
            v-if="scope.row.db==1"
          >查看</el-button>
          <el-button
            type="text"
            size="small"
            @click="act_click(1,scope.row)"
            v-if="scope.row.db!==1"
          >进入</el-button>
          <el-button
            type="text"
            size="small"
            @click="act_click(2,scope.row)"
            :disabled="scope.row.dis_practice||scope.row.dis_blom||$route.query.dis_click==1"
          >练习</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array
    }
  },
  data () {
    return {
    }
  },

  components: {},

  methods: {
    headClass () { //表头居中显示
      return "text-align:center"
    },
    // 表格点击事件
    act_click (type, val) {
      // type  0 查看  1 进入  2 练习
      if (type == 0) {
        this.$router.push({ path: '/adultSummary' })
      } else if (type == 1) {
        console.log(val)
        // activityId
        let last_time = val.dis_blom ? 1 : 0
        if (val.isStart == 2) {
          this.$router.push({ path: '/adultActivityDetails', query: { activityId: val.activityId, dis_blom: 1 } })
        } else {
          this.$router.push({ path: '/adultActivityDetails', query: { activityId: val.activityId, dis_blom: last_time } })
        }
      } else if (type == 2) {
        if (val.isStart == 2) {
          this.$message.error('该活动已结束!')
        } else {
          this.$router.push(`/adultEducation/answer?data=${JSON.stringify(val)}`)
        }
      }
    },
  }
}

</script>
<style lang='less' scoped>
</style>
